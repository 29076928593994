<template>
  <div>
    <banner
      title="Ferias"
      type="banner--admin"
      :options="{padding: '30px'}">
    </banner>
    <div class="pt-3 px-7">
      <div class="row">
        <div class="col-12 d-flex justify-end">
          <v-btn
            color="secondary"
            class="text--primary text-none elevation-0"
            @click="openFairModal()"
            dark>
            Crear feria
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <v-text-field
            v-model="params.filter"
            label="Buscar"
            v-on:keyup.enter="search"
            outlined
            dense
            single-line
            hide-details
          ></v-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-end">
          <v-btn
            color="primary"
            @click="search"
            class="white--text text-none elevation-0 mr-2">
            <v-icon
              left
              dark>
              fa-search
            </v-icon>
            Buscar
          </v-btn>
          <v-btn
            color="default"
            @click="clean"
            class="text-none elevation-0">
            <v-icon
              left
              dark>
              fa-broom
            </v-icon>
            Limpiar
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table ref="fairs" :url="url" :params="params" :headers="headers">
            <template v-slot:item.isActive="{ item }">
              <v-chip
                :color="(item.isActive) ? 'secondary' : 'red'"
                dark
              >
                {{ (item.isActive) ? 'Si' : 'No' }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="openFairModal(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      dark
                      small
                    >
                      fa fa-edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar feria</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2 elevation-0"
                    @click="managePlaces(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      dark
                      small
                    >
                      fa fa-store
                    </v-icon>
                  </v-btn>
                </template>
                <span>Administrar espacios</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2 elevation-0"
                    @click="manageConferences(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      dark
                      small
                    >
                      fas fa-cog
                    </v-icon>
                  </v-btn>
                </template>
                <span>Configuración</span>
              </v-tooltip>
            </template>
          </data-table>
        </div>
      </div>
      <fair-modal ref="fairModal" @onClose="onClose"/>
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import headers from '@/views/private/fairs/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import FairModal from '@/views/private/fairs/components/FairModal'
import Banner from '@/components/banner/Banner'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    Banner,
    DataTable,
    FairModal
  },
  data () {
    return {
      headers,
      url: FAIRS_URL,
      params: {
        filter: ''
      }
    }
  },
  methods: {
    clean () {
      this.params.filter = null
      this.search()
    },
    managePlaces (item) {
      this.$router.push({
        name: 'places',
        params: { id: item.id }
      })
    },
    manageConferences (item) {
      this.$router.push({
        name: 'conferences',
        params: { id: item.id }
      })
    },
    onClose () {
      this.$refs.fairs.getDataFromApi()
    },
    openFairModal (item) {
      this.$refs.fairModal.open(item)
    },
    search () {
      this.$refs.fairs.getDataFromApi()
    }
  },
  mixins: [
    crudServiceMixin
  ],
  name: 'Fairs'
}
</script>
